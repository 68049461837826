<script>
import Notification from '@/components/Notification.vue'
export default {
    components: { Notification }, 
    props: ['tabs', 'selectedTab', 'notifications'],
    emits: ['update:selectedTab'],
    data(){
        return {
           areaWidth: this.width == undefined ? '80%' : this.width
        }
    },
    methods:
    {
        changeTab(tab)
        {
            this.$emit('update:selectedTab', tab);
        }
    }
}
</script>
<template>
<ul class="Tabs-content-area">
    <li v-for="tab in tabs" @click="changeTab(tab)" :class="tab == selectedTab ? 'wow-color ko': ''">
        <div v-if="notifications != undefined">
            <Notification :notifications="notifications" :level="'tab'" :levelName="tab" :color="'#d98df6'" :right="'5px'" :top="'-10px'"></Notification>
        </div>
        {{ tab }}
    </li>
</ul>
</template>

<style scoped>
   .Tabs-content-area
   {
        border-bottom: 2px solid #ddd;
        list-style: none;
        margin: 0px;
        padding: 0px;
        margin-bottom:20px;
        font-family: 'Raleway', sans-serif;
    }

    .ko
    {
        font-weight: bold;
    }
   .Tabs-content-area li
   {
        margin:0px;
        padding: 8px 18px;
        font-size: 13px;
        letter-spacing: 1px;
        display: inline-block;
        text-transform: uppercase;
        cursor: pointer;
    }

    li { position: relative; }
</style>