<script>
export default {
    props: ['booking', 'customer', 'url', 'imgWidth'],
    methods:
    {
       book(booking)
       {
        if(booking.duration_unit == 'Dagar')
            {
                this.$router.push('/add-days-booking/' + booking.id); 
            }
            else{
                this.$router.push('/add-hours-booking/' + booking.id);                 
            }
       }
    },
}
</script>
<template>
    <div>
        <div class="position-relative">
            <div class="BookingItem-img-area" :style="{'width': imgWidth }" >
                <img v-if="booking.image_id" :src="url + 'uploads/'+ customer.id+'/images/thumbnails/'+ booking.image_name" />  
                <i v-else class="fa-regular fa-image wow-color wow-color-bg"></i>
            </div>
            <h4 class="wow-color">{{ booking.name }}</h4>
        </div>
        <div class="BookingItem-max-booking">
            <p>Maxlängd på bokning: {{ booking.duration_time }} {{ booking.duration_unit }}</p>
            <button class="btn wow-color float-right margin-top-15" @click="book(booking)">Boka</button>
        </div>
    </div>
</template>
<style scoped>
    .BookingItem-img-area
    {
        width:320px; 
        height: 150px; 
        overflow: hidden;
        text-align: center;
    }

    .BookingItem-max-booking
    {
        margin: 30px 10px; 
        overflow:auto;
        padding-bottom: 10px;
    }

    .BookingItem-wrapper
    {
        display:flex;
        flex-wrap: wrap;

    }

    .BookingItem-img-area i
    {
        display:block;
        padding-top: 40px;
        font-size: 60px;
        background-color:aliceblue;
        height: 100vh;
    }

    img
    {
        width: 100%;
    }

    h4{
        margin-bottom:0px;
        padding:10px;
        width: 50%;
        position: absolute;
        bottom: -20px;
        left: 10px;
    }

    @media only screen and (max-width: 1000px) {
        .BookingItem-img-area {
            height: auto;
            width: auto;
        }
        
    }

</style>